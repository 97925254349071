import { StatusVocabulary, TypeCodes } from 'constants/data'

const A_BANNERS_CLICK = 'A_BANNERS_CLICK'

const A_POMOSH_MEDICAM = 'pomoshmedikam_open'
const A_REGISTRATION_CLICK_BUTTON = 'registration_click_button'
const A_REGISTRATION_CLICK_BUTTON2 = 'registration_click_button2'
const A_LETTERSUB_CLICK_SUCCESS = 'lettersub_click_success'
const A_LETTERSUB_CLICK = 'lettersub_click'

const A_DOZATOR_CLICK = 'dozator_click'
const A_DOZATOR_OPEN = 'dozator_open'
const NMO_WIDGET_CLICK = 'nmo_widget_click'

const A_SUBSCRIBE_LECTOR = 'lectur_click_subscription'
const A_UNSUBSCRIBE_LECTOR = 'lectur_click_unsubscribe '
const A_SUBSCRIBE_WEBINAR = 'webinar_click_subscription'
const A_UNSUBSCRIBE_WEBINAR = 'webinar_click_unsubscribe'
const A_UNAUTH_SUB_WEBINAR = 'want_view'

const A_READ_NOTIFICATION = 'notification_click'
const A_READ_ALL_NOTIFICATION = 'notification_read'
const A_OPEN_NOTIFICATION_LIST = 'notification_open'

const A_CONFIRM_FILTER = 'filter_use'
const A_RECOMENDATION = 'recomendation_click'
const A_STRING_SEARCH = 'search_use'
const A_DOWNLOAD_MATERIAL = 'attachment_download'

const A_REGISTRATION_CLICK_FORM_FIELD = 'registration_click_form_field'
const A_AUTHORIZATION_TRANSITION = 'authorization_click_button'
const A_REGISTRATION_TRANSITION = 'registration_click_button'
const A_RECOVERY_TRANSITION = 'recoverypass_click_button'
const A_AUTHORIZATION_TINKOFF_TRANSITION = 'authorization_tinkoff_click_button'
const A_AUTHORIZATION_YANDEX_TRANSITION = 'authorization_yandex_click_button'
const A_AUTH_LOGIN_ERRORS = 'login_errors'
const A_REGISTRATION_2ND_STEP = 'registration_2nd_step'

const A_REGISTRATION_SUCCESS = 'registration_success_response'
const A_REGISTRATION_SUCCESS_TRANSITION = 'registration_success'
const A_AUTHORIZATION_SUCCESS_TRANSITION = 'authorization_success'
const A_RECOVERY_SUCCESS_TRANSITION = 'recoverypass_success'

const A_PROFILE_COMPLETED = 'profile_completed'

const A_ARTICLE_OPEN = 'article_open'
const A_ARTICLE_VIDEO_OPEN = 'article_video_open'
const A_CLINIC_OPEN = 'clinic_open'
const A_ARTICLE_SCROLL = 'article_scroll'
const A_CLINIC_SCROLL = 'clinic_scroll'
const A_NMO_SCROLL = 'nmo_scroll'
const A_INTRO_SCROLL = 'intro_scroll'
const A_MAIN_SCROLL = 'main_scroll'
const A_COURSE_SCROLL = 'program_scroll'

const A_WEBINAR_FUTURE_OPEN = 'webinar_future_open'
const A_WEBINAR_LIVE_OPEN = 'webinar_live_open'
const A_WEBINAR_PAST_OPEN = 'webinar_past_open'
const A_WEBINAR_ALL_OPEN = 'webinar_all_open'
const A_WEBINAR_SECTION_OPEN = 'webinar_section_open'
const A_VIDEO_PROJECT_OPEN = 'video_project_open'
const A_VIDEO_PROJECT_CLICK = 'video_project_click'
const A_VIDEO_OPEN = 'video_open'
const A_NEXT_VIDEO_OPEN = 'next_video_open'

const A_FAQ_BUTTON = 'webinar_FAQ_open'
const A_ON_AIR = 'webinar_click_stream'
const A_WEBINAR_VIEW_NMO_SUCCESS = 'webinar_view_nmo_success'
const A_WEBINAR_END = 'webinar_view_view_success'
const A_WEBINAR_RATE = 'rating_click'

const A_NONAME_WEBINAR_OPEN = 'noname_webinar_open'
const A_ANKETA_OPEN = 'anketa_open'
const A_ANKETA_SENT = 'anketa_sent'
const A_ANKETA_CLICK_FORM_FIELD = 'anketa_click_form_field'

const A_TOGGLE_SELECT = 'feed_selected'
const A_SHARE_OPEN = 'share_open'
const A_SHARE_CLICK = 'share_click'
const A_TELEGRAM_CLICK = 'telegram_click'
const A_TG_BANNER_CLICK = 'tgbanner_click'
const A_TG_BANNER_SHOWN = 'tgbanner_shown'
const A_LIKE_CLICK_TRUE = 'like_click_true'
const A_LIKE_CLICK_FALSE = 'like_click_false'
const A_SUMMARY_OPEN = 'summary_open_click'

const A_QUESTIONNAIRE_BEGIN = 'question_answered'
const A_QUESTIONNAIRE_COMPLETED = 'all_questions_answered'
const A_FORM_COMPLETED = 'form_completed'

const A_COURSE_MORE_SPEC_OPEN = 'spec_more'
const A_COURSE_NAVIGATION_OPEN = 'content_show'
const A_COURSE_BTN_NEXT = 'next_topic'
const A_COURSE_BTN_PREV = 'previous_topic'
const A_COURSE_CHOOSE_NAV_THEME = 'click_content'
const A_COURSE_SPEED_VIDEO_CHANGE = 'speed_change'
const A_COURSE_WATCHING_DURATION = 'video_view_duration'
const A_COURSE_CONSULTATION_CLICK = 'consultation_click'
const A_COURSE_CONSULTATION_CLICK_2 = 'consultation_click_2'
const A_COURSE_CHOICE_PROGRAM = 'choice_program'
const A_COURSE_CLICK_PROGRAM = 'click_program'
const A_COURSE_CONTETN_SHOW = 'content_show'
const A_COURSE_ENROLL = 'course_enroll'

const A_NMO_MAIN_OPEN = 'nmo_instruction_main'
const A_NMO_ACCREDITATION_OPEN = 'nmo_accreditation'
const A_NMO_ACTIVATION_OPEN = 'nmo_instruction_application'
const A_NMO_REPORT_OPEN = 'nmo_instruction_report'
const A_NMO_REQUEST_OPEN = 'nmo_instruction_request'
const A_NMO_INPUT_FIO_CLICK = 'contact_button'
const A_NMO_INPUT_PHONE_CLICK = 'phone_button'
const A_NMO_INPUT_EMAIL_CLICK = 'email_button'
const A_NMO_READ_MORE_ACTIVATION = 'nmo_instruction_application'
const A_NMO_READ_MORE_REPORT = 'nmo_instruction_report'
const A_NMO_READ_MORE_REQUEST = 'nmo_instruction_request'
const A_NMO_MAIN_SCROLL = 'nmo_main_scroll'
const A_NMO_ACTIVATION_SCROLL = 'nmo_point_scroll'
const A_NMO_REPORT_SCROLL = 'nmo_instruction_report_scroll'
const A_NMO_REQUEST_SCROLL = 'nmo_application_scroll'
const A_NMO_INSTRUCTION_POINT = 'nmo_instruction_point'

const A_COURSE_MAIN_OPEN = 'nmo_scroll_free'
const A_COURSE_STUDY_FREE = 'nmo_study_free'
const A_COURSE_TRY_FREE_STOMACH = 'stomach_free'
const A_COURSE_TRY_FREE_OSTEO = 'osteo_free'
const A_COURSE_NMO_BUTTON = 'nmo_button_free'

const A_STOMACH_MAIN_OPEN = 'stomach_free_scroll'
const A_STOMACH_BUY_FULL = 'stomach_buy'
const A_STOMACH_TRY_FREE = 'stomach_free'
const A_STOMACH_VIEWED_ALL = 'stomach_viewed_all'

const A_OSTEO_MAIN_OPEN = 'osteo_free_scroll'
const A_OSTEO_BUY_FULL = 'osteo_buy'
const A_OSTEO_TRY_FREE = 'osteo_free'
const A_OSTEO_VIEWED_ALL = 'osteo_viewed_all'

const A_GAME_INVITATION = 'invitation_open'
const A_GAME_INVITATION_LINK_COPIED = 'invitation_copy'
const A_GAME_MEDCOIN_CLICK = 'medcoin_click'

const A_MAYOLY_OPEN = 'mayoly_main_open'
const A_MAYOLY_SCROLL = 'mayoly_main_scroll'

const A_AD_SALE = 'popup_sale_open'
const A_AD_SALE_FIELD = 'popup_sale_field'
const A_AD_SALE_OK = 'popup_sale_success_response'
const A_AD_TG_CLICK = 'popup_tg_click'
const A_AD_TG_OPEN = 'popup_tg_open'

const A_POPUP_SUCCES = 'popup_success'
const A_POPUP_OPEN = 'popup_open'
const A_POPUP_FIELD = 'popup_field'
const A_BANNER_CLICK = 'banner_click'

const A_ONLINE_SCHOOL_GERIATRICS_24 = 'online_school_geriatrics_24'

const PLACEFLAG_MAIN = 'main'
const PLACEFLAG_MAIN_SCROLL = 'main_scroll'
const PLACEFLAG_CHAT = 'webinar_chat'
const PLACEFLAG_LIVE = 'webinar_live'
const PLACEFLAG_LIVE_END = 'webinar_live_end'
const PLACEFLAG_PAST = 'webinar_past'
const PLACEFLAG_FUTURE = 'webinar_future'
const PLACEFLAG_FUTURE_RECOMMENDATION = 'webinar_future_recommendation'
const PLACEFLAG_PAST_RECOMMENDATION = 'webinar_past_recommendation'
const PLACEFLAG_HOUR_BEFORE = 'webinar_hour_before'
const PLACEFLAG_SEARCH = 'webinar_search'
const PLACEFLAG_COMMENT = 'comment'
const PLACEFLAG_VIDEO = 'video'
const PLACEFLAG_ARTICLE = 'article'
const PLACEFLAG_CLINIC = 'clinic'
const PLACEFLAG_PODCAST = 'podcast'
const PLACEFLAG_LIBRARY = 'library'
const PLACEFLAG_LECTOR_PERSONAL = 'lectur_personalpage'
const PLACEFLAG_LECTOR_LIST = 'lectur_list'
const PLACEFLAG_EMAIL = 'email'
const PLACEFLAG_EMAIL_CONFIRM = 'email_confirm'
const PLACEFLAG_BANNER = 'banner'
const PLACEFLAG_NMO_FAQ = 'nmo_faq'
const PLACEFLAG_HOW_TO_GET_NMO = 'how_to_get'
const PLACEFLAG_HOW_TO_GET_FIFTY_NMO = 'how_to_get_fifty'
const PLACEFLAG_ACTIVATE_NMO = 'activate'
const PLACEFLAG_BACKEND = 'automat'
const PLACEFLAG_PROFILE = 'profile'
const PLACEFLAG_QUESTIONNAIRE = 'questionnaire'
const PLACEFLAG_CROWDFUNDING = 'crowdfunding'
const PLACEFLAG_COURSE = 'course'
const PLACEFLAG_COURSE_TAB = 'course_tab'
const PLACEFLAG_CLINIC_CASE_TAB = 'clinic_case_tab'
const PLACEFLAG_CLINIC_CASE = 'clinic_case'
const PLACEFLAG_GAME = 'game'
const PLACEFLAG_COURSE_CARDIO = 'cardio_course'
const PLACEFLAG_CARDIO = 'cardio'
const PLACEFLAG_CARDIO_START_PAGE = 'cardio_start_page'
const PLACEFLAG_CARDIO_MEDIUM_PAGE = 'cardio_medium_page'
const PLACEFLAG_CARDIO_END_PAGE = 'cardio_end_page'
const PLACEFLAG_CARDIO_NMO = 'cardio_nmo'
const PLACEFLAG_MINICARDIO = 'cardio18'
const PLACEFLAG_MINICARDIO_START_PAGE = 'cardio18_start_page'
const PLACEFLAG_MINICARDIO_MEDIUM_PAGE = 'cardio18_medium_page'
const PLACEFLAG_MINICARDIO_END_PAGE = 'cardio18_end_page'
const PLACEFLAG_MINICARDIO_NMO = 'cardio18_nmo'
const PLACEFLAG_OSTEO_NMO = 'osteo_middle_page'
const PLACEFLAG_OSTEO_START_PAGE = 'osteo_start_page'
const PLACEFLAG_OSTEO_MIDDLE_PAGE = 'osteo_middle_page'
const PLACEFLAG_OSTEO_END_PAGE = 'osteo_end_page'
const PLACEFLAG_OSTEO_END = 'osteo_end'
const PLACEFLAG_NEURO = 'neuro'
const PLACEFLAG_NEURO_START_PAGE = 'neuro_start_page'
const PLACEFLAG_NEURO_MIDDLE_PAGE = 'neuro_middle_page'
const PLACEFLAG_NEURO_END_PAGE = 'neuro_end_page'
const PLACEFLAG_GERIATRICS = 'geriatrics'
const PLACEFLAG_GERIATR_START_PAGE = 'geriatr_start_page'
const PLACEFLAG_GERIATR_MIDDLE_PAGE = 'geriatr_middle_page'
const PLACEFLAG_GERIATR_END_PAGE = 'geriatr_end_page'
const PLACEFLAG_GERIATRICS_BASIC = 'geriatrics18'
const PLACEFLAG_GERIATR_BASIC_START_PAGE = 'geriatr18_start_page'
const PLACEFLAG_GERIATR_BASIC_MIDDLE_PAGE = 'geriatr18_middle_page'
const PLACEFLAG_GERIATR_BASIC_END_PAGE = 'geriatr18_end_page'
const PLACEFLAG_VIDEO_PROJECT = 'video_project'
const PLACEFLAG_SOMNOLOGY_START_PAGE = 'somnology_start_page'
const PLACEFLAG_SOMNOLOGY_MIDDLE_PAGE = 'somnology_middle_page'
const PLACEFLAG_SOMNOLOGY_MIDDLE_PAGE_2 = 'somnology_middle_page_2'
const PLACEFLAG_SOMNOLOGY_END_PAGE = 'somnology_end_page'
const PLACEFLAG_SOMNOLOGY = 'somnology'
const PLACEFLAG_GASTROINTESTINAL = 'gastrointestinal_diseases'
const PLACEFLAG_GASTROINTESTINAL_START_PAGE = 'gastrointestinal_diseases_start_page'
const PLACEFLAG_GASTROINTESTINAL_END_PAGE = 'gastrointestinal_diseases_end_page'
const PLACEFLAG_GASTROINTESTINAL_MIDDLE_PAGE = 'gastrointestinal_diseases_middle_page'
const PLACEFLAG_PROGRAM_START_PAGE = 'program_start_page'
const PLACEFLAG_PROGRAM_MIDDLE_PAGE = 'program_middle_page'
const PLACEFLAG_PROGRAM_END_PAGE = 'program_end_page'

const ORIGIN_FEED = 'feed'
const ORIGIN_BANNER = 'banner'
const ORIGIN_WEBINAR_ALL = 'webinar'
const ORIGIN_RECOMMENDATIONS = 'webinar_recom'
const ORIGIN_RECOMMENDATIONS_ARTICLE = 'article_recom'
const ORIGIN_WEBINAR_ENDED = 'web_end_recom'
const ORIGIN_NMO_INSTR_LINK = 'nmo_link'
const ORIGIN_NMO_INSTR_BTN = 'nmo_button'
const ORIGIN_COURSE_ALL = 'program'
const ORIGIN_INFORM_LINE = 'line'
const ORIGIN_LK = 'profile'
const ORIGIN_ACTIVE = 'active'
const ORIGIN_ACTIVE_END = 'active_end'
const ORIGIN_PAST = 'past'

const ORIGIN_GAME = 'game'
const ORIGIN_BANNER_GAME_MAIN = 'banner_main'
const ORIGIN_BANNER_GAME_PROGRAM = 'banner_program'
const ORIGIN_BANNER_GAME_WEBINAR = 'banner_webinar'

const ORIGIN_POMOSH_MEDICAM = 'banner'

const PAYMENT_BTN_CLICK = 'payment_click'
const PAYMENT_SUCCESS = 'payment_success'
const PAYMENT_FAILED = 'payment_failed'

const CREATE_CLINIC_CASE_CLICK = 'clinic_create'
const SENT_CLINIC_CASE_CLICK = 'clinic_sent'
const SPEC_CLINIC_CASE_CLICK = 'clinic_spec'
const INPUT_CLINIC_CASE_CLICK = 'clinic_input'

const PROGRESSBAR_LINK_CLICK = 'progressbar_link_click'

const YES = 'yes'
const NO = 'no'

const AnalyticWebinarPlace = {
  [StatusVocabulary.FUTURE]: PLACEFLAG_FUTURE,
  [StatusVocabulary.HOUR_BEFORE]: PLACEFLAG_HOUR_BEFORE,
  [StatusVocabulary.ACTIVE]: PLACEFLAG_LIVE,
  [StatusVocabulary.PAST]: PLACEFLAG_PAST,
}

const AnalyticArticlePlace = {
  [TypeCodes.ARTICLE]: PLACEFLAG_ARTICLE,
  [TypeCodes.CLINIC]: PLACEFLAG_CLINIC,
  [TypeCodes.VIDEO]: PLACEFLAG_VIDEO,
}

const WithoutCampaignQuery = [PLACEFLAG_FUTURE, PLACEFLAG_CLINIC]

export {
  A_SUBSCRIBE_LECTOR,
  A_UNSUBSCRIBE_LECTOR,
  A_CONFIRM_FILTER,
  A_SUBSCRIBE_WEBINAR,
  A_UNSUBSCRIBE_WEBINAR,
  A_UNAUTH_SUB_WEBINAR,
  A_RECOMENDATION,
  A_READ_NOTIFICATION,
  A_READ_ALL_NOTIFICATION,
  A_OPEN_NOTIFICATION_LIST,
  A_STRING_SEARCH,
  A_DOWNLOAD_MATERIAL,
  A_REGISTRATION_SUCCESS,
  A_ON_AIR,
  A_REGISTRATION_CLICK_FORM_FIELD,
  A_WEBINAR_END,
  A_WEBINAR_RATE,
  A_AUTHORIZATION_TRANSITION,
  A_AUTH_LOGIN_ERRORS,
  A_REGISTRATION_TRANSITION,
  A_RECOVERY_TRANSITION,
  A_REGISTRATION_SUCCESS_TRANSITION,
  A_RECOVERY_SUCCESS_TRANSITION,
  A_AUTHORIZATION_SUCCESS_TRANSITION,
  A_AUTHORIZATION_TINKOFF_TRANSITION,
  A_AUTHORIZATION_YANDEX_TRANSITION,
  A_ARTICLE_OPEN,
  A_ARTICLE_VIDEO_OPEN,
  A_CLINIC_OPEN,
  A_SUMMARY_OPEN,
  A_NMO_MAIN_OPEN,
  A_ARTICLE_SCROLL,
  A_CLINIC_SCROLL,
  A_NMO_SCROLL,
  A_INTRO_SCROLL,
  A_MAIN_SCROLL,
  A_COURSE_SCROLL,
  A_WEBINAR_FUTURE_OPEN,
  A_WEBINAR_LIVE_OPEN,
  A_WEBINAR_PAST_OPEN,
  A_WEBINAR_ALL_OPEN,
  A_WEBINAR_VIEW_NMO_SUCCESS,
  A_WEBINAR_SECTION_OPEN,
  A_VIDEO_PROJECT_OPEN,
  A_VIDEO_PROJECT_CLICK,
  A_VIDEO_OPEN,
  A_NEXT_VIDEO_OPEN,
  A_NONAME_WEBINAR_OPEN,
  A_ANKETA_OPEN,
  A_ANKETA_SENT,
  A_ANKETA_CLICK_FORM_FIELD,
  PLACEFLAG_MAIN,
  PLACEFLAG_MAIN_SCROLL,
  PLACEFLAG_CHAT,
  PLACEFLAG_LIVE,
  PLACEFLAG_FUTURE,
  PLACEFLAG_FUTURE_RECOMMENDATION,
  PLACEFLAG_PAST_RECOMMENDATION,
  PLACEFLAG_COMMENT,
  PLACEFLAG_BANNER,
  PLACEFLAG_NMO_FAQ,
  PLACEFLAG_HOW_TO_GET_NMO,
  PLACEFLAG_HOW_TO_GET_FIFTY_NMO,
  PLACEFLAG_ACTIVATE_NMO,
  PLACEFLAG_QUESTIONNAIRE,
  YES,
  NO,
  A_FAQ_BUTTON,
  PLACEFLAG_PAST,
  PLACEFLAG_LECTOR_PERSONAL,
  PLACEFLAG_LECTOR_LIST,
  PLACEFLAG_EMAIL,
  PLACEFLAG_ARTICLE,
  PLACEFLAG_VIDEO,
  PLACEFLAG_SEARCH,
  PLACEFLAG_EMAIL_CONFIRM,
  PLACEFLAG_LIVE_END,
  PLACEFLAG_BACKEND,
  PLACEFLAG_PROFILE,
  PLACEFLAG_CROWDFUNDING,
  PLACEFLAG_LIBRARY,
  PLACEFLAG_COURSE,
  PLACEFLAG_CLINIC_CASE,
  PLACEFLAG_CLINIC_CASE_TAB,
  PLACEFLAG_COURSE_TAB,
  PLACEFLAG_GAME,
  PLACEFLAG_PODCAST,
  PLACEFLAG_COURSE_CARDIO,
  PLACEFLAG_CARDIO,
  PLACEFLAG_CARDIO_START_PAGE,
  PLACEFLAG_CARDIO_MEDIUM_PAGE,
  PLACEFLAG_CARDIO_END_PAGE,
  PLACEFLAG_CARDIO_NMO,
  PLACEFLAG_MINICARDIO,
  PLACEFLAG_MINICARDIO_START_PAGE,
  PLACEFLAG_MINICARDIO_MEDIUM_PAGE,
  PLACEFLAG_MINICARDIO_END_PAGE,
  PLACEFLAG_MINICARDIO_NMO,
  PLACEFLAG_NEURO,
  PLACEFLAG_NEURO_START_PAGE,
  PLACEFLAG_NEURO_MIDDLE_PAGE,
  PLACEFLAG_NEURO_END_PAGE,
  PLACEFLAG_GERIATRICS,
  PLACEFLAG_GERIATR_START_PAGE,
  PLACEFLAG_GERIATR_MIDDLE_PAGE,
  PLACEFLAG_GERIATR_END_PAGE,
  PLACEFLAG_GERIATRICS_BASIC,
  PLACEFLAG_GERIATR_BASIC_START_PAGE,
  PLACEFLAG_GERIATR_BASIC_MIDDLE_PAGE,
  PLACEFLAG_GERIATR_BASIC_END_PAGE,
  PLACEFLAG_VIDEO_PROJECT,
  PLACEFLAG_OSTEO_NMO,
  PLACEFLAG_OSTEO_START_PAGE,
  PLACEFLAG_OSTEO_MIDDLE_PAGE,
  PLACEFLAG_OSTEO_END_PAGE,
  PLACEFLAG_OSTEO_END,
  PLACEFLAG_SOMNOLOGY_START_PAGE,
  PLACEFLAG_SOMNOLOGY_MIDDLE_PAGE,
  PLACEFLAG_SOMNOLOGY_MIDDLE_PAGE_2,
  PLACEFLAG_SOMNOLOGY_END_PAGE,
  PLACEFLAG_SOMNOLOGY,
  PLACEFLAG_GASTROINTESTINAL,
  PLACEFLAG_GASTROINTESTINAL_START_PAGE,
  PLACEFLAG_GASTROINTESTINAL_MIDDLE_PAGE,
  PLACEFLAG_GASTROINTESTINAL_END_PAGE,
  A_PROFILE_COMPLETED,
  A_SHARE_OPEN,
  A_SHARE_CLICK,
  A_LIKE_CLICK_TRUE,
  A_LIKE_CLICK_FALSE,
  A_TOGGLE_SELECT,
  A_QUESTIONNAIRE_BEGIN,
  A_QUESTIONNAIRE_COMPLETED,
  A_FORM_COMPLETED,
  AnalyticWebinarPlace,
  AnalyticArticlePlace,
  ORIGIN_FEED,
  ORIGIN_BANNER,
  ORIGIN_WEBINAR_ALL,
  ORIGIN_RECOMMENDATIONS,
  ORIGIN_RECOMMENDATIONS_ARTICLE,
  ORIGIN_WEBINAR_ENDED,
  ORIGIN_NMO_INSTR_LINK,
  ORIGIN_NMO_INSTR_BTN,
  ORIGIN_COURSE_ALL,
  ORIGIN_INFORM_LINE,
  ORIGIN_LK,
  ORIGIN_ACTIVE,
  ORIGIN_ACTIVE_END,
  ORIGIN_PAST,
  ORIGIN_GAME,
  ORIGIN_BANNER_GAME_MAIN,
  ORIGIN_BANNER_GAME_PROGRAM,
  ORIGIN_POMOSH_MEDICAM,
  ORIGIN_BANNER_GAME_WEBINAR,
  PAYMENT_BTN_CLICK,
  PAYMENT_SUCCESS,
  PAYMENT_FAILED,
  CREATE_CLINIC_CASE_CLICK,
  SENT_CLINIC_CASE_CLICK,
  SPEC_CLINIC_CASE_CLICK,
  INPUT_CLINIC_CASE_CLICK,
  A_TELEGRAM_CLICK,
  A_TG_BANNER_CLICK,
  A_TG_BANNER_SHOWN,
  A_COURSE_MORE_SPEC_OPEN,
  A_COURSE_NAVIGATION_OPEN,
  A_COURSE_BTN_NEXT,
  A_COURSE_BTN_PREV,
  A_COURSE_CHOOSE_NAV_THEME,
  A_COURSE_SPEED_VIDEO_CHANGE,
  A_COURSE_WATCHING_DURATION,
  A_COURSE_CONSULTATION_CLICK,
  A_COURSE_CHOICE_PROGRAM,
  A_COURSE_CLICK_PROGRAM,
  A_COURSE_CONTETN_SHOW,
  A_NMO_INPUT_FIO_CLICK,
  A_NMO_INPUT_EMAIL_CLICK,
  A_NMO_INPUT_PHONE_CLICK,
  A_NMO_READ_MORE_ACTIVATION,
  A_NMO_READ_MORE_REPORT,
  A_NMO_READ_MORE_REQUEST,
  A_NMO_ACTIVATION_OPEN,
  A_NMO_REPORT_OPEN,
  A_NMO_REQUEST_OPEN,
  A_NMO_ACCREDITATION_OPEN,
  A_NMO_MAIN_SCROLL,
  A_NMO_ACTIVATION_SCROLL,
  A_NMO_REPORT_SCROLL,
  A_NMO_REQUEST_SCROLL,
  A_NMO_INSTRUCTION_POINT,
  A_COURSE_MAIN_OPEN,
  A_COURSE_STUDY_FREE,
  A_COURSE_TRY_FREE_STOMACH,
  A_COURSE_TRY_FREE_OSTEO,
  A_COURSE_NMO_BUTTON,
  A_STOMACH_MAIN_OPEN,
  A_STOMACH_BUY_FULL,
  A_STOMACH_VIEWED_ALL,
  A_STOMACH_TRY_FREE,
  A_OSTEO_MAIN_OPEN,
  A_OSTEO_BUY_FULL,
  A_OSTEO_TRY_FREE,
  A_OSTEO_VIEWED_ALL,
  A_COURSE_CONSULTATION_CLICK_2,
  WithoutCampaignQuery,
  PROGRESSBAR_LINK_CLICK,
  A_GAME_INVITATION,
  A_GAME_INVITATION_LINK_COPIED,
  A_GAME_MEDCOIN_CLICK,
  A_DOZATOR_CLICK,
  A_DOZATOR_OPEN,
  NMO_WIDGET_CLICK,
  A_POMOSH_MEDICAM,
  A_REGISTRATION_CLICK_BUTTON,
  A_REGISTRATION_CLICK_BUTTON2,
  A_REGISTRATION_2ND_STEP,
  A_LETTERSUB_CLICK_SUCCESS,
  A_LETTERSUB_CLICK,
  A_MAYOLY_OPEN,
  A_MAYOLY_SCROLL,
  A_AD_SALE,
  A_AD_SALE_FIELD,
  A_AD_SALE_OK,
  A_AD_TG_CLICK,
  A_AD_TG_OPEN,
  A_BANNERS_CLICK,
  A_POPUP_SUCCES,
  A_POPUP_OPEN,
  A_POPUP_FIELD,
  A_BANNER_CLICK,
  A_ONLINE_SCHOOL_GERIATRICS_24,
  PLACEFLAG_PROGRAM_START_PAGE,
  PLACEFLAG_PROGRAM_MIDDLE_PAGE,
  PLACEFLAG_PROGRAM_END_PAGE,
  A_COURSE_ENROLL,
}
