import { useEffect } from 'react'

const useDisableAMO = () => {
  useEffect(() => {
    const findAmo = document.querySelector('body')

    if (findAmo) findAmo.classList.add('hide-amo')

    const makeVisible = () => {
      if (findAmo) findAmo.classList.remove('hide-amo')
    }
    return makeVisible
  }, [])
}

export default useDisableAMO
